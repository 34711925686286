<template>
    <div class="contain">
        <searchBar :searchInput="false"></searchBar>
        <div class="bgf handle">
            <div class="file-tmp" ref="dragArea">
                <div class="remark"></div>
                <img :src="file" alt="协议文件" ref="agrfile">
                <div class="flex_cen dragModule btn" ref="dragModule" :style="{top:top+'px',left:left+'px'}" @mousedown="drag">
                    <p class="el-icon-rank color"></p>
                    <div class="color fs_14">
                        <p>签署区域</p>
                        <p>拖动至用户签名位置</p>
                    </div>
                </div>
            </div>
            <div class="flex_cen mt_15">
                <el-button type="info" class="next-btn mr_20" @click="back">上一步</el-button>
                <el-button type="primary" class="next-btn" @click="next">预 览</el-button>
            </div>
        </div>

        <el-dialog title="操作提示" v-model="dialogVisible" width="460px" top="26vh" :before-close="handleClose">
            <template #title>
                <div class="fs_18 bold">操作提示</div>
            </template>
            <div class="center content fs_16 color_000">请将右下角的签署区域，移动至需要用户签署的位置</div>
            <template #footer>
                <span class="flex_cen">
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { s_setItem, s_getItem, s_clear } from "@/common/cache"
export default {
	data() {
		return {
			id: '',
			file: '', // 签名页
			dialogVisible: false,
			isDrag: false,
			left: 50, // x轴坐标
			top: 550 // y轴坐标
		}
	},
	created() {
		let agrs = s_getItem('_agrs') || []
		if(agrs.length==0) {
			this.back()
		}
		agrs.forEach(item=>{
			if(item.isSign) {
				this.file = item.url
			}
		})
		this.id = this.$route.params.id
	},
	beforeRouteLeave (to, from, next) {
		if(to.name === 'Preview' && to.name === 'UploadProtocol') {
			s_clear()
		}
		next()
	},
	mounted() {
		this.top = s_getItem('_drag_top') || this.$refs.agrfile.height*0.8
		this.left = s_getItem('_drag_left') || 85
	},
	methods: {
		drag(ev) {
			let dragModule = this.$refs.dragModule
			let dragArea = this.$refs.dragArea
			let disX
			let disY
			let left
			let top
			let that = this
			let oEvent = ev || event
			// clientX，clientY：鼠标点击位置到浏览器可视区域x，y距离
			// offsetLeft，offsetTop:元素距离父级元素的偏移距离
			disX = oEvent.clientX - dragModule.offsetLeft
			disY = oEvent.clientY - dragModule.offsetTop
			that.isDrag = true
			// 鼠标移动事件
			document.onmousemove = function (_ev) {
				// 加入该判断拖拽更流畅
				if (that.isDrag) {
					let _oEvent = _ev || event
					// 计算点击元素到父级元素的定位top，left距离
					left = _oEvent.clientX - disX
					top = _oEvent.clientY - disY
					/** 限定拖拽范围，限定拖拽元素在指定的范围内 */
					// 限定左边界和上边界
					if (left < 0) {
						left = 0
					}
					if (top < 0) {
						top = 0
					}
					// 限定右边界的距离(当left=父元素宽-子元素宽时，刚好子元素放在父元素最右边)
					if (left > dragArea.clientWidth - dragModule.clientWidth) {
						left = dragArea.clientWidth - dragModule.clientWidth
					}
					// 限定下边界的距离(当t=父元素高-子元素高时，刚好子元素放在父元素最下边)
					if (top > dragArea.clientHeight - dragModule.clientHeight) {
						top = dragArea.clientHeight - dragModule.clientHeight
					}
					dragModule.style.left = left + 'px'
					dragModule.style.top = top + 'px'
				}
			}
			document.onmouseup = function () {
				that.left = left
				that.top = top
				that.isDrag = false
				document.onmousemove = null
				document.onmouseup = null //鼠标抬起来后，onmouseup事件本身也没意义了，所以最好清理掉
			}
			return false //阻止默认行为，空的div在低版本ff下，第二次拖动手型会变异常
		},
		next() {
			s_setItem('_drag_top', this.top)
			s_setItem('_drag_left', this.left)
			this.$router.push(`/employment/preview/${this.id}`)
		},
		back() {
			this.$router.go(-1)
		}
	}
}
</script>

<style lang='less' scoped>
.handle {
    padding: 50px 0;
    .file-tmp {
        width: 600px;
        position: relative;
        margin: 0 auto;
        .remark {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 3;
            background: rgba(0, 0, 0, 0.3);
        }
    }
    .next-btn {
        width: 200px;
        height: 52px;
        font-size: 18px;
        margin-top: 30px;
    }
    .mr_20 {
        margin-right: 20px;
    }
    .dragModule {
        width: 220px;
        height: 100px;
        user-select: none;
        padding: 0 20px;
        border: 1px dashed #1890FF;
        border-radius: 2px;
        position: absolute;
        z-index: 9;
        // left: 50px;
        // top: 60%;
        cursor: move !important;
        background: rgba(255,255,255,.8);
        .el-icon-rank {
            font-size: 40px;
            margin-right: 10px;
        }
    }
}
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
.content {
    border-top: 1px solid #ddd;
    height: 130px;
    padding: 60px 30px 30px;
}
/deep/.el-button {
    width: 162px;
    height: 42px;
    font-size: 16px;
}
</style>